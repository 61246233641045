/**
 * A concrete error class.
 *
 * If this is thrown, the ncurrent session has expired,
 * i.e.: neither the access token nor the refresh token are valid
 * and the user must login again to receive session tokens.
 */
class SessionExpiredError extends Error {
  constructor() {
    super('Session has expired');
  }
}

export default SessionExpiredError;
