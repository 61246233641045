import * as React from 'react';
import { ButtonGroup, Button } from 'ebs-design';

interface Props {
  options: number[];
  value: number;
  onChange: (value: number) => void;
  size?: 'small' | 'medium' | 'large';
}

export const PerPage: React.FC<Props> = ({ options, value, onChange, size = 'small' }) => {
  return (
    <ButtonGroup className="per-page">
      {options.map((item) => (
        <Button
          key={item}
          onClick={() => onChange(item)}
          type={item === value ? 'primary' : 'ghost'}
          size={size}
        >
          {item}
        </Button>
      ))}
    </ButtonGroup>
  );
};
