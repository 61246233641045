/**
 * An error class for cases when the API returned
 * a response we did not expect.
 */
class ResponseError extends Error {
  public constructor(
    readonly url: string,
    readonly status: number,
    readonly body: BodyInit | null | undefined,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    readonly response: any,
  ) {
    super('Unexpected response error');
  }
}

export default ResponseError;
