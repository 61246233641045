import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Card,
  Col,
  Form,
  Loader,
  Radio,
  Row,
  Space,
  useForm,
  useNotify,
  Label,
  Textarea,
} from 'ebs-design';
import { SmartSelect } from 'components/organisms';
import { useQueryParams } from 'hooks/useQueryParams';
import { useCitiesAPI, useVisitsAPI, useRegionsAPI } from 'api';
import { ValidationError } from 'errors';
import { ContactsVisit, Visit, VisitType } from 'types';
import { DynamicForm } from 'features/visits/DynamicForm';

export const VisitForm: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { back } = useQueryParams();
  const history = useHistory();
  const notify = useNotify();
  const [form] = useForm();
  const queryClient = useQueryClient();

  const { getCities } = useCitiesAPI();
  const { getVisitById, postVisit, patchVisit } = useVisitsAPI();
  const { getRegionsList } = useRegionsAPI();

  const [type, setType] = React.useState<string>();
  const [regionId, setRegionId] = React.useState<number>();
  const [isDuplicate, setIsDuplicate] = React.useState<(number | string)[]>();
  const [contacts, setContacts] = React.useState<ContactsVisit[]>([]);

  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    form.setFieldsValue({
      type: VisitType.MARKETING,
    });
    setType(VisitType.MARKETING);
  }, []);

  const { data, isLoading } = useQuery<Visit>(['visit', { id }], getVisitById, {
    enabled: Boolean(id),
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: ({ city, type, lead, contacts, description }) => {
      form.setFieldsValue({
        city: city.id,
        type,
        description,
        contacts,
        ...(lead && { lead: lead.name }),
      });
      setRegionId(city.region.id);
      setType(type);
      setContacts(contacts as ContactsVisit[]);
    },
  });

  const { mutate, isSuccess } = useMutation(id ? patchVisit : postVisit, {
    onError: (e) => {
      notify.error({ title: t('error.someThingIsWrong') });
      if (e instanceof ValidationError) {
        form.setFields(e.fields);

        const alreadyExistsError = e.fields[0]?.errors?.map((error, i) =>
          error['phone_number']?.[0].includes('already exists') ? i : error['phone_number']?.[0],
        );

        setIsDuplicate(alreadyExistsError);
      }
    },
    onSuccess: () => {
      notify.success({ title: t('success.successDataSave') });
      queryClient.invalidateQueries(['visits']);
      queryClient.invalidateQueries(['visit']);
      setIsDuplicate([]);
    },
  });

  React.useEffect(() => {
    if (isSuccess) {
      if (redirect) {
        history.push('/visits');
      }
      history.push(back);
    }
  }, [isSuccess]);

  const onChangeRegion = (value: number): void => {
    setRegionId(value);
    form.setFieldsValue({ city: undefined });
  };

  const onSubmitForm = async (values): Promise<void> => {
    const { lead, ...rest } = values;

    const leadId = lead === data?.lead?.name ? data?.lead?.id : lead;

    mutate({
      ...rest,
      contacts: contacts ? contacts : [],
      lead: lead ? leadId : null,
      id,
      is_visit: true,
    });
  };

  const onResetForm = (): void => form.resetFields();

  const onValuesChange = (value): void => {
    if (value.type) {
      setType(value.type);
    }

    if (value.contacts) {
      const index = value.contacts.findIndex((i) => i);

      setContacts((prevState) =>
        prevState.map((item, i) => {
          if (i === index) {
            item = { ...item, ...value.contacts[index] };
          }

          return item;
        }),
      );
    }
  };

  return (
    <Loader loading={isLoading} size="regular">
      <div className="contact-create">
        <Card>
          <Card.Body>
            <Form
              form={form}
              id="create-visit"
              onValuesChange={onValuesChange}
              onFinish={onSubmitForm}
              className="contact-create_form visit-form"
            >
              <Row g={4} className="mt-0">
                <Col size={12}>
                  <h3>{t(`${id ? 'visits.edit' : 'visits.addVisit'}`)}</h3>
                </Col>
                <Col size={6}>
                  <Label
                    className="visit-form__label"
                    text={
                      <>
                        {t('contacts.region')}
                        <span className="ebs-form__field__required">*</span>
                      </>
                    }
                  />
                  <SmartSelect
                    search
                    queryKey="regions"
                    api={getRegionsList}
                    placeholder={t('contacts.region')}
                    value={regionId}
                    onChange={onChangeRegion}
                  />
                </Col>
                <Col size={6}>
                  <Form.Field name="city" label={t('contacts.city')} rules={[{ required: true }]}>
                    <SmartSelect
                      search
                      queryKey="cities"
                      disabled={!regionId}
                      api={getCities}
                      filters={{ region: regionId }}
                      placeholder={t('contacts.city')}
                      selected={
                        data?.city && {
                          value: data.city.id,
                          text: data.city.name,
                        }
                      }
                    />
                  </Form.Field>
                </Col>
              </Row>
              <hr />
              <Row className="mt-20">
                <Col size={6}>
                  <Form.Field
                    name="type"
                    label={t('visits.type')}
                    rules={[{ required: true }]}
                    className="contact-type-radio"
                  >
                    <Radio
                      options={[
                        {
                          text: t('visits.marketing'),
                          value: VisitType.MARKETING,
                          disabled: !!id,
                        },
                        { text: t('visits.planned'), value: VisitType.PLANNED, disabled: !!id },
                      ]}
                    />
                  </Form.Field>
                </Col>
              </Row>
              <hr />
              <Row className="mt-20">
                <Col size={6}>
                  <Form.Field
                    name="description"
                    label={t('visits.description')}
                    className="textarea-static-width"
                  >
                    <Textarea placeholder={t('task.description')} />
                  </Form.Field>
                </Col>
              </Row>
              <hr />

              <DynamicForm
                type={type}
                contacts={contacts}
                isDuplicate={isDuplicate}
                setDuplicates={setIsDuplicate}
                setContacts={setContacts}
                form={form}
              />
            </Form>
          </Card.Body>
          <Card.Footer>
            <Space align="end" justify="end">
              <Button onClick={onResetForm}>{t('buttons.cancel')}</Button>
              <Button type="primary" submit form="create-visit" onClick={() => setRedirect(true)}>
                {t('buttons.save')}
              </Button>
            </Space>
          </Card.Footer>
        </Card>
      </div>
    </Loader>
  );
};
