import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Form, Icon, Loader, Modal, Space, Textarea, useNotify } from 'ebs-design';
import { ActionCard } from 'features/leads/ActionCard';
import { ConfirmModal } from 'components/molecules';
import { DocumentForm } from 'components/organisms';
import { DocumentItem } from './DocumentItem';
import { useContactAPI, useDocumentsAPI } from 'api';
import { Document, Lead } from 'types';
import { Check } from 'resources/icons';

const formId = 'add-document';

interface DocumentCardProps {
  lead: Lead;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ lead }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getLeadDocuments, deleteDocument } = useDocumentsAPI();
  const { sendViber } = useContactAPI();

  const [editId, setEditId] = React.useState();
  const [deleteId, setDeleteId] = React.useState();
  const [sendId, setSendId] = React.useState();

  const { data, isLoading } = useQuery<Document[]>(['documents', { id }], getLeadDocuments, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const mutation = useMutation(deleteDocument, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),
    onSuccess: () => {
      queryClient.invalidateQueries(['documents']);
      setDeleteId(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const mutationSend = useMutation(sendViber, {
    onError: () => notify.error({ title: t('error.someThingIsWrong') }),

    onSuccess: () => {
      handleCancelSend();
      notify.success({ title: t('success.sendDocument') });
    },
  });

  const handleSend = (values): void => {
    mutationSend.mutate({ ...values, attachment: sendId, id: lead?.contact?.id });
  };

  const onEdit = (id): void => {
    setEditId(id);
  };
  const onDelete = (id): void => {
    setDeleteId(id);
  };

  const onSend = (id): void => {
    setSendId(id);
  };

  const handleDelete = (): void => {
    if (deleteId) {
      mutation.mutate(deleteId);
    }
  };

  const handleCancelSend = (): void => {
    setSendId(undefined);
  };

  return (
    <>
      <ActionCard
        header={t('lead.documents')}
        className="mt-20"
        backgroundColor="#3DC343"
        formId={formId}
        count={data?.length}
        editId={editId}
        unsetEdit={() => setEditId(undefined)}
        FormComponent={DocumentForm}
      >
        <Loader loading={isLoading}>
          {data?.length
            ? data?.map((document, key) => (
                <DocumentItem
                  key={key}
                  document={document}
                  lead={lead}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onSend={onSend}
                />
              ))
            : t('main.noData')}
        </Loader>
      </ActionCard>
      {deleteId && (
        <ConfirmModal onSuccess={handleDelete} onCancel={() => setDeleteId(undefined)} />
      )}

      {sendId && (
        <Modal
          closeOnClickOutside={false}
          open
          header={t('document.sendDocumentToViber')}
          onClose={handleCancelSend}
        >
          <Form onFinish={handleSend}>
            <Modal.Content>
              <Form.Field name="text_message" label={t('main.text')}>
                <Textarea />
              </Form.Field>
            </Modal.Content>

            <Modal.Footer>
              <Space justify="space-between">
                <Button onClick={handleCancelSend}>{t('buttons.cancel')}</Button>

                <Button
                  type="primary"
                  submit
                  loading={mutationSend.isLoading}
                  prefix={<Icon className="action-card__save-button" component={Check} />}
                >
                  {t('buttons.send')}
                </Button>
              </Space>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};
