import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useSetState } from 'react-use';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Icon, Select, Space, useNotify } from 'ebs-design';
import { useContactAPI, useUsersAPI, useLeadsAPI } from 'api';
import LogsPanelContext from 'contexts/LogsPanelContext';
import { Filters } from 'components/molecules';
import { SmartSelect } from 'components/organisms';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { List, Message, UserAlone, UserCircle } from 'resources/icons';
import { defaultFilters, formatSelectValueToNumber } from 'utils';
import { Contact, LogType, Results, User } from 'types';
import { MergeLeadForm } from '../../MergeLeadForm';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';

interface ActivityFiltersProps {
  something?: any;
}

interface FiltersProps {
  type?: string;
  created_by?: string;
  contact?: string;
}

interface defaultStateToProps {
  visibleModal: boolean;
  id: number | null;
}

const defaultState = {
  visibleModal: false,
  id: null,
};

export const ActivityFilters: React.FC<ActivityFiltersProps> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { updateQuery } = useQueryUpdate();
  const { getUsers } = useUsersAPI();
  const { getLeadContacts } = useContactAPI();
  const { getLeadsList, merge } = useLeadsAPI();
  const queryClient = useQueryClient();
  const params = useQueryParams();
  const notify = useNotify();

  const { isOpenCreateNote, isOpenCreateMeeting, toggleCreateNote, toggleCreateMeeting } =
    React.useContext(LogsPanelContext);

  const [state, setState] = useSetState<defaultStateToProps>(defaultState);

  const [usersFilter, setUsersFilter] = useSetState(defaultFilters);

  const [filters, setFilters] = useSetState<FiltersProps>(params);

  const { data: dataUsers } = useQuery<Results<User>>(
    ['users', { lead: id, ...usersFilter }],
    getUsers,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const { data: dataContact } = useQuery<Contact[]>(['lead-contacts', { id }], getLeadContacts, {
    onError: () => {
      notify.error({ title: t('error.someThingIsWrong') });
    },
  });

  const mergeLead = useMutation((lead_to_merge: number) => merge({ id, lead_to_merge }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['lead']);
      setState({ visibleModal: false, id: null });
      notify.success({ title: t('success.successDataSave') });
    },
  });

  const handleSubmit = (): void => {
    if (state.id) {
      mergeLead.mutate(state.id);
    }
  };

  React.useEffect(() => {
    updateQuery({ ...filters, page: params.page && 1, per_page: params.per_page });
  }, [filters]);

  const handleFilters = (value): void => {
    setFilters(value);
  };

  return (
    <>
      <Filters className="activity-filter" onChange={handleFilters}>
        <Space>
          <Filters.Select
            prefix={<Icon className="activity-filter__select-icon" component={List} />}
            placeholder={t('logs.type')}
            value={Array.isArray(params.type) ? params.type : params.type && [params.type]}
            valueMode="inline"
            field="type"
            mode="multiple"
          >
            <Select.Options>
              {Object.values(LogType).map((item, key) => (
                <Select.Options.Item value={item} key={`type-${key}`}>
                  {t(`logs.${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>
          <Filters.Select
            prefix={<Icon className="activity-filter__select-icon" component={UserCircle} />}
            placeholder={t('logs.users')}
            value={params.created_by}
            field="created_by"
          >
            <Select.Search
              value={usersFilter.search}
              onSearch={(search) => setUsersFilter({ search })}
            />
            <Select.Options>
              {(dataUsers?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-user`} value={item.id}>
                  {item?.first_name || item?.last_name
                    ? `${item?.first_name} ${item?.last_name}`
                    : item.email}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={dataUsers?.count || 0}
              limit={usersFilter.per_page}
              page={usersFilter.page}
              setPage={(page) => setUsersFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            prefix={<Icon className="activity-filter__select-icon" component={UserAlone} />}
            placeholder={t('logs.allContacts')}
            value={formatSelectValueToNumber(params.contact)}
            field="contact"
          >
            <Select.Options>
              {(dataContact || []).map((item, key) => (
                <Select.Options.Item key={`${key}-user`} value={item.id}>
                  {`${item?.first_name} ${item?.last_name}`}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>
        </Space>

        {useRolePermission([
          UserRoleCRM.marketing,
          UserRoleCRM.manager,
          UserRoleCRM.admin,
          UserRoleCRM.expert,
        ]) && (
          <Space>
            <ButtonGroup className="activity-filter__button-group">
              <Button
                className="activity-filter__button-icon"
                type="primary"
                prefix={<Icon type="apps" model="bold" />}
              />
              <Button type="ghost" onClick={() => setState({ visibleModal: true })}>
                {t('activities.merge')}
              </Button>
            </ButtonGroup>

            <ButtonGroup className="activity-filter__button-group">
              <Button
                className="activity-filter__button-icon"
                type="primary"
                prefix={<Icon component={List} />}
              />
              <Button type="ghost" onClick={toggleCreateNote} disabled={isOpenCreateNote}>
                {t('activities.addNote')}
              </Button>
            </ButtonGroup>

            <ButtonGroup className="activity-filter__button-group">
              <Button
                className="activity-filter__button-icon"
                type="primary"
                prefix={<Icon component={Message} />}
              />
              <Button type="ghost" onClick={toggleCreateMeeting} disabled={isOpenCreateMeeting}>
                {t('activities.event')}
              </Button>
            </ButtonGroup>
          </Space>
        )}
      </Filters>

      {state.visibleModal && (
        <MergeLeadForm id={state.id} onClose={() => setState(defaultState)} onSubmit={handleSubmit}>
          <SmartSelect
            search
            queryKey="merge-leads-list"
            api={getLeadsList}
            optionsMode="box"
            value={state.id}
            onChange={(value) => {
              setState({ id: value });
            }}
          />
        </MergeLeadForm>
      )}
    </>
  );
};
