import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSetState } from 'react-use';
import { useQuery } from 'react-query';
import { Button, Icon, Select, Space } from 'ebs-design';
import {
  useBranchesAPI,
  useLeadsListAPI,
  useUsersAPI,
  useRegionsAPI,
  useCitiesAPI,
  useActivityDomainsAPI,
} from 'api';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';
import { useRolePermission } from 'hooks/useRolePermission';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Filters, CustomPeriod, PeriodType } from 'components/molecules';
import { Calendar, Map, Plus, SortingDown, UserAlone } from 'resources/icons';
import { defaultNonPagination, formatSelectValueToNumber, getPeriodByName } from 'utils';
import { dateFormat, dateFormatAPI, format } from 'libs';
import {
  User,
  LeadsList,
  Results,
  List,
  LeadStatus,
  DefaultFiltersProps,
  OpportunityStatus,
  Region,
  Cities,
  ActivityDomains,
} from 'types';

interface FiltersProps extends DefaultFiltersProps {
  search?: string;
  branch?: string;
  owner?: string;
  status?: string;
  list?: string;
  period?: string;
  period_start?: string;
  period_end?: string;
  region_id?: string;
  city_id?: string;
  activity_domain?: string;
}

interface ContactFilterProps {
  count?: number;
}

export const ContactFilter: React.FC<ContactFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { updateQuery } = useQueryUpdate();
  const { getBranches } = useBranchesAPI();
  const { getUsers } = useUsersAPI();
  const { getLeadsList } = useLeadsListAPI();
  const { getRegions } = useRegionsAPI();
  const { getCities } = useCitiesAPI();
  const { getActivityDomainList } = useActivityDomainsAPI();

  const [contactListFilter, setContactListFilter] = useSetState(defaultNonPagination);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);
  const [branchesFilter, setBranchesFilter] = useSetState(defaultNonPagination);
  const [filters, setFilters] = useSetState<FiltersProps>(params);
  const [regionsFilters, setRegionsFilters] = useSetState(defaultNonPagination);
  const [citiesFilters, setCitiesFilters] = useSetState(defaultNonPagination);
  const [activityDomainsFilters, setActivityDomainsFilters] = useSetState(defaultNonPagination);

  const [openCalendarPicker, setOpenCalendarPicker] = React.useState(false);

  const { data: branches } = useQuery<Results<List>>(['branches', branchesFilter], getBranches);

  const { data: users } = useQuery<Results<User>>(
    [
      'users',
      {
        ...usersFilter,
        include_archived: useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin]),
      },
    ],
    getUsers,
  );

  const { data: leadsList } = useQuery<Results<LeadsList>>(
    ['leads-list', contactListFilter],
    getLeadsList,
  );

  const { data: regions } = useQuery<Results<Region>>(['regions', regionsFilters], getRegions);

  const { data: cities } = useQuery<Results<Cities>>(
    ['cities', { ...citiesFilters, region: params?.region_id }],
    getCities,
    {
      enabled: Boolean(params?.region_id),
    },
  );

  const { data: activityDomains } = useQuery<Results<ActivityDomains>>(
    ['activity-domain', activityDomainsFilters],
    getActivityDomainList,
  );

  React.useEffect(() => {
    updateQuery({ ...filters, page: params.page && 1, per_page: params.per_page });
  }, [filters]);

  const statusOptions = React.useMemo(
    () => [
      { label: t('filter.prospecting'), value: LeadStatus.PROSPECTING },
      { label: t('filter.notInterested'), value: LeadStatus.NOT_INTERESTED },
      { label: t('filter.unqualified'), value: LeadStatus.UNQUALIFIED },
      { label: t('filter.notEligible'), value: LeadStatus.NOT_ELIGIBLE },
      { label: t('filter.qualified'), value: LeadStatus.QUALIFIED },
    ],
    [t],
  );

  const handleFilters = (value): void => {
    if (value?.period !== undefined) {
      if (value?.period === 'custom') {
        setOpenCalendarPicker(true);
      } else {
        const [period_start, period_end] = getPeriodByName(value.period);
        setFilters({ period_start, period_end, ...value });
      }
    } else if ('period' in value) {
      setFilters({ period_start: undefined, period_end: undefined, ...value });
    } else if ('region_id' in value || value?.region_id === undefined) {
      setFilters({ city_id: undefined, ...value });
    } else {
      setFilters(value);
    }
  };

  const handleCustomPeriod = (period_start: string, period_end: string): void => {
    setFilters({ period_start, period_end, period: 'custom' });
    setOpenCalendarPicker(false);
  };

  return (
    <>
      {openCalendarPicker && (
        <CustomPeriod
          onClose={() => setOpenCalendarPicker(false)}
          onChange={handleCustomPeriod}
          initialValue={
            [
              params.period_start && format(params.period_start, dateFormatAPI, dateFormat),
              params.period_end && format(params.period_end, dateFormatAPI, dateFormat),
            ] as PeriodType
          }
        />
      )}
      <Filters className="mb-0" onChange={handleFilters}>
        <Space wrap>
          <h3 className="title">{`${t(`contacts.contacts`)}${count ? ` (${count})` : ``}`}</h3>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
          {useRolePermission([UserRoleCRM.admin]) && (
            <Filters.Select
              prefix={<Icon component={Map} />}
              placeholder={t('filter.branch')}
              value={formatSelectValueToNumber(params.branch)}
              field="branch"
            >
              <Select.Options>
                {(branches?.results || []).map((item, key) => (
                  <Select.Options.Item key={`${key}-branch`} value={item.key}>
                    {item.label}
                  </Select.Options.Item>
                ))}
              </Select.Options>
              <Select.Pagination
                count={branches?.count || 0}
                limit={branchesFilter.per_page}
                page={branchesFilter.page}
                setPage={(page) => setBranchesFilter({ page })}
                mode="scroll"
              />
            </Filters.Select>
          )}
          {useRolePermission([UserRoleCRM.manager, UserRoleCRM.admin, UserRoleCRM.expert]) && (
            <Filters.Select
              prefix={<Icon component={UserAlone} />}
              placeholder={t('filter.creditExpert')}
              value={params.owner}
              field="owner"
            >
              <Select.Options>
                {(users?.results || []).map((item, key) => (
                  <Select.Options.Item key={`${key}-user`} value={item.id}>
                    {item?.first_name || item?.last_name
                      ? `${item?.first_name} ${item?.last_name}`
                      : item.email}
                  </Select.Options.Item>
                ))}
              </Select.Options>
              <Select.Pagination
                count={users?.count || 0}
                limit={usersFilter.per_page}
                page={usersFilter.page}
                setPage={(page) => setUsersFilter({ page })}
                mode="scroll"
              />
            </Filters.Select>
          )}
          <Filters.Select
            value={formatSelectValueToNumber(params.region_id)}
            placeholder={t('contacts.region')}
            field="region_id"
          >
            <Select.Options>
              {(regions?.results || []).map((item) => (
                <Select.Options.Item key={`${item.id}-region`} value={item.id}>
                  {item?.name}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={regions?.count || 0}
              limit={regionsFilters.per_page}
              page={regionsFilters.page}
              setPage={(page) => setRegionsFilters({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            value={formatSelectValueToNumber(params.city_id)}
            disabled={!params.region_id}
            placeholder={t('contacts.city')}
            field="city_id"
          >
            <Select.Options>
              {(cities?.results || []).map((item) => (
                <Select.Options.Item key={`${item.id}-city`} value={item.id}>
                  {item?.name}
                </Select.Options.Item>
              ))}
            </Select.Options>

            <Select.Pagination
              count={cities?.count || 0}
              limit={citiesFilters.per_page}
              page={citiesFilters.page}
              setPage={(page) => setCitiesFilters({ page })}
              mode="regular"
            />
          </Filters.Select>
          <Filters.Select
            prefix={<Icon component={SortingDown} />}
            placeholder={t('filter.contactList')}
            value={formatSelectValueToNumber(params.list)}
            field="list"
          >
            <Select.Options>
              {(leadsList?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-list`} value={item.id}>
                  {item.name}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={leadsList?.count || 0}
              limit={contactListFilter.per_page}
              page={contactListFilter.page}
              setPage={(page) => setContactListFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            className="period-drop-list"
            prefix={<Icon component={Calendar} />}
            placeholder={t('filter.period')}
            field="period"
            value={params.period}
          >
            <Select.Options>
              <Select.Options.Item value="today">{t('filter.today')}</Select.Options.Item>
              <Select.Options.Item value="week">{t('filter.this_week')}</Select.Options.Item>
              <Select.Options.Item value="month">{t('filter.this_month')}</Select.Options.Item>
              <Select.Options.Item value="quarter">{t('filter.this_quarter')}</Select.Options.Item>
              <Select.Options.Item value="year">{t('filter.this_year')}</Select.Options.Item>
              <Select.Options.Item value="yesterday">{t('filter.yesterday')}</Select.Options.Item>
              <Select.Options.Item value="previous_week" className="period-drop-list__separator">
                {t('filter.previous_week')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_month">
                {t('filter.previous_month')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_year">
                {t('filter.previous_year')}
              </Select.Options.Item>
              <Select.Options.Item value="all_time">{t('filter.all_time')}</Select.Options.Item>
              <Select.Options.Item
                value="custom"
                className="period-drop-list__custom period-drop-list__separator"
              >
                {t('filter.custom')}
              </Select.Options.Item>
            </Select.Options>
          </Filters.Select>
          <Filters.Select placeholder={t('filter.status')} value={params.status} field="status">
            <Select.Options>
              {statusOptions.map((option, i) => (
                <Select.Options.Item key={i} value={option.value}>
                  {option.label}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>
          <Filters.Select
            placeholder={t('filter.opportunityStatus')}
            value={params.opportunity_status}
            field="opportunity_status"
          >
            <Select.Options>
              {Object.values(OpportunityStatus).map((item, key) => (
                <Select.Options.Item value={item} key={`status-${key}`}>
                  {t(`opportunities.${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>

          <Filters.Select
            placeholder={t('nomenclature.activityDomains')}
            value={formatSelectValueToNumber(params.activity_domain)}
            field="activity_domain"
          >
            <Select.Options>
              {activityDomains?.results?.map(({ key, label }) => (
                <Select.Options.Item key={key} value={key}>
                  {label}
                </Select.Options.Item>
              ))}
            </Select.Options>

            <Select.Pagination
              count={activityDomains?.count || 0}
              limit={activityDomainsFilters.per_page}
              page={activityDomainsFilters.page}
              setPage={(page) => setActivityDomainsFilters({ page })}
              mode="regular"
            />
          </Filters.Select>

          {useRolePermission([
            UserRoleCRM.admin,
            UserRoleCRM.expert,
            UserRoleCRM.manager,
            UserRoleCRM.marketing,
          ]) && (
            <Link to={{ pathname: '/contacts/create' }}>
              <Button type="primary" prefix={<Icon component={Plus} />}>
                {t('buttons.addContact')}
              </Button>
            </Link>
          )}
        </Space>
      </Filters>
    </>
  );
};
