import * as React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSetState } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Select, Space } from 'ebs-design';
import { CustomPeriod, Filters, PeriodType } from 'components/molecules';
import { useQueryParams, useQueryUpdate } from 'hooks/useQueryParams';
import { Calendar, List, Plus, UserAlone } from 'resources/icons';
import { useUsersAPI, useRegionsAPI, useCitiesAPI } from 'api';
import { defaultNonPagination, formatSelectValueToNumber, getPeriodByName } from 'utils';
import { dateFormat, dateFormatAPI, format } from 'libs';
import { Results, User, VisitType, Cities, Region } from 'types';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';

interface CallsFilterProps {
  count?: number;
}

interface FiltersProps {
  search?: string;
  type?: string;
  agent?: string;
  created_at?: string;
  created_at_from?: string;
  created_at_to?: string;
  city__region?: string;
  city?: string;
}

export const VisitsFilter: React.FC<CallsFilterProps> = ({ count }) => {
  const { t } = useTranslation();
  const params = useQueryParams();
  const { getUsers } = useUsersAPI();
  const { getRegions } = useRegionsAPI();
  const { getCities } = useCitiesAPI();
  const { updateQuery } = useQueryUpdate();
  const [openCalendarPicker, setOpenCalendarPicker] = React.useState(false);
  const [usersFilter, setUsersFilter] = useSetState(defaultNonPagination);
  const [regionsFilters, setRegionsFilters] = useSetState(defaultNonPagination);
  const [citiesFilters, setCitiesFilters] = useSetState(defaultNonPagination);

  const [filters, setFilters] = useSetState<FiltersProps>(params);
  const { data: users } = useQuery<Results<User>>(['users', usersFilter], getUsers);
  const { data: regions } = useQuery<Results<Region>>(['regions', regionsFilters], getRegions);
  const { data: cities } = useQuery<Results<Cities>>(
    ['cities', { ...citiesFilters, region: params?.city__region }],
    getCities,
    {
      enabled: Boolean(params.city__region),
    },
  );

  React.useEffect(() => {
    updateQuery({ ...filters, page: params.page && 1, per_page: params.per_page });
  }, [filters]);

  const handleFilters = (value): void => {
    if (value?.created_at !== undefined) {
      if (value?.created_at === 'custom') {
        setOpenCalendarPicker(true);
      } else {
        const [created_at_from, created_at_to] = getPeriodByName(value.created_at);
        setFilters({ created_at_from, created_at_to, ...value });
      }
    } else if ('created_at' in value) {
      setFilters({ created_at_from: undefined, created_at_to: undefined, ...value });
    } else if ('city__region' in value || value?.city__region === undefined) {
      setFilters({ city: undefined, ...value });
    } else {
      setFilters(value);
    }
  };

  const handleCustomPeriod = (created_at_from: string, created_at_to: string): void => {
    setFilters({ created_at_from, created_at_to, created_at: 'custom' });
    setOpenCalendarPicker(false);
  };

  const handleOpenCalendarPicker = (): void => {
    setOpenCalendarPicker(false);
  };

  return (
    <>
      {openCalendarPicker && (
        <CustomPeriod
          onClose={handleOpenCalendarPicker}
          onChange={handleCustomPeriod}
          initialValue={
            [
              params.created_at_from && format(params.created_at_from, dateFormatAPI, dateFormat),
              params.created_at_to && format(params.created_at_to, dateFormatAPI, dateFormat),
            ] as PeriodType
          }
        />
      )}
      <Filters className="mb-0" onChange={handleFilters}>
        <Space justify="start">
          <h3 className="title">{`${t(`visits.visits`)}${count ? ` (${count})` : ``}`}</h3>
          <Filters.Search field="search" value={params.search} placeholder={t('filter.search')} />
        </Space>
        <Space justify="end">
          <Filters.Select
            value={formatSelectValueToNumber(params.city__region)}
            placeholder={t('contacts.region')}
            field="city__region"
          >
            <Select.Options>
              {(regions?.results || []).map((item) => (
                <Select.Options.Item key={`${item.id}-region`} value={item.id}>
                  {item?.name}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={regions?.count || 0}
              limit={regionsFilters.per_page}
              page={regionsFilters.page}
              setPage={(page) => setRegionsFilters({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            value={formatSelectValueToNumber(params.city)}
            disabled={!params.city__region}
            placeholder={t('contacts.city')}
            field="city"
          >
            <Select.Options>
              {(cities?.results || []).map((item) => (
                <Select.Options.Item key={`${item.id}-city`} value={item.id}>
                  {item?.name}
                </Select.Options.Item>
              ))}
            </Select.Options>

            <Select.Pagination
              count={cities?.count || 0}
              limit={citiesFilters.per_page}
              page={citiesFilters.page}
              setPage={(page) => setCitiesFilters({ page })}
              mode="regular"
            />
          </Filters.Select>
          <Filters.Select
            prefix={<Icon component={UserAlone} />}
            placeholder={t('filter.creditExpert')}
            value={params.agent}
            field="agent"
          >
            <Select.Options>
              {(users?.results || []).map((item, key) => (
                <Select.Options.Item key={`${key}-user`} value={item.id}>
                  {item?.first_name || item?.last_name
                    ? `${item?.first_name} ${item?.last_name}`
                    : item.email}
                </Select.Options.Item>
              ))}
            </Select.Options>
            <Select.Pagination
              count={users?.count || 0}
              limit={usersFilter.per_page}
              page={usersFilter.page}
              setPage={(page) => setUsersFilter({ page })}
              mode="scroll"
            />
          </Filters.Select>
          <Filters.Select
            prefix={<Icon component={List} />}
            placeholder={t('visits.type')}
            value={params.type}
            field="type"
          >
            <Select.Options>
              {Object.values(VisitType).map((item, key) => (
                <Select.Options.Item key={`${key}-type`} value={item}>
                  {t(`visits.${item}`)}
                </Select.Options.Item>
              ))}
            </Select.Options>
          </Filters.Select>
          <Filters.Select
            className="created-at-drop-list"
            prefix={<Icon component={Calendar} />}
            placeholder={t('filter.created_at')}
            field="created_at"
            value={params.created_at}
          >
            <Select.Options>
              <Select.Options.Item value="today">{t('filter.today')}</Select.Options.Item>
              <Select.Options.Item value="week">{t('filter.this_week')}</Select.Options.Item>
              <Select.Options.Item value="month">{t('filter.this_month')}</Select.Options.Item>
              <Select.Options.Item value="quarter">{t('filter.this_quarter')}</Select.Options.Item>
              <Select.Options.Item value="year">{t('filter.this_year')}</Select.Options.Item>
              <Select.Options.Item value="yesterday" className="period-drop-list__separator">
                {t('filter.yesterday')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_week">
                {t('filter.previous_week')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_month">
                {t('filter.previous_month')}
              </Select.Options.Item>
              <Select.Options.Item value="previous_year">
                {t('filter.previous_year')}
              </Select.Options.Item>
              <Select.Options.Item value="all_time">{t('filter.all_time')}</Select.Options.Item>
              <Select.Options.Item
                value="custom"
                className="period-drop-list__custom period-drop-list__separator"
              >
                {t('filter.custom')}
              </Select.Options.Item>
            </Select.Options>
          </Filters.Select>

          {useRolePermission([
            UserRoleCRM.marketing,
            UserRoleCRM.manager,
            UserRoleCRM.admin,
            UserRoleCRM.expert,
          ]) && (
            <Link to={{ pathname: '/visits/create' }}>
              <Button type="primary" prefix={<Icon component={Plus} />}>
                {t('visits.addVisit')}
              </Button>
            </Link>
          )}
        </Space>
      </Filters>
    </>
  );
};
