import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'ebs-design';
import { Hyperlink, Upload } from 'resources/icons';

export const DropZone: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="drop-zone">
      <div className="drop-zone__upload-icon">
        <Icon component={Upload} />
      </div>
      <div className="drop-zone__drag-drop">{t('dropZone.dragDrop')}</div>
      <div className="drop-zone__or">{t('dropZone.or')}</div>
      <Button
        type="ghost"
        prefix={<Hyperlink className="drop-zone__browse-file__icon" />}
        className="drop-zone__browse-file"
      >
        {t('dropZone.browseFile')}
      </Button>
    </div>
  );
};
