import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Actions, Card, Table, useNotify, Loader, Modal, Space, Button } from 'ebs-design';
import { Column, Properties, Results, Visit } from 'types';
import { dateFormat, dateFormatAPI, dateTimeFormat, format } from 'libs';
import { defaultFilters } from 'utils';
import { useVisitsAPI } from 'api';
import { useQueryParams } from 'hooks/useQueryParams';
import { Pagination } from 'components/organisms';
import { Put } from 'components/atoms';
import { VisitsFilter } from 'features/visits/Filters';
import { useRolePermission } from 'hooks/useRolePermission';
import { UserRoleCRM } from 'hooks/useFetcher/interfaces';

export const Visits: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { push } = history;
  const params = useQueryParams();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const { getVisits, deleteVisit } = useVisitsAPI();

  const [removeVisit, setRemoveVisit] = React.useState<number>();

  const { data: visits, isLoading } = useQuery<Results<Visit>>(
    [
      'visits',
      {
        ...defaultFilters,
        ...params,
        created_at_from:
          params.created_at_from && format(params.created_at_from, dateFormatAPI, dateFormat),
        created_at_to:
          params.created_at_to && format(params.created_at_to, dateFormatAPI, dateFormat),
        created_at: undefined,
      },
    ],
    getVisits,
    {
      onError: () => {
        notify.error({ title: t('error.someThingIsWrong') });
      },
    },
  );

  const deleteVisitMutation = useMutation<Properties, unknown, number>(deleteVisit, {
    onError: () =>
      notify.error({
        title: t('error.someThingIsWrong'),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['visits']);
      setRemoveVisit(undefined);
      notify.success({ title: t('success.successDataDelete') });
    },
  });

  const handleDeleteVisit = (): void => {
    if (removeVisit) {
      deleteVisitMutation.mutate(removeVisit);
    }
  };

  const handleCancelDelete = (): void => {
    setRemoveVisit(undefined);
  };

  const columns: Column<Visit>[] = React.useMemo(
    () => [
      {
        title: t('calls.id'),
        dataIndex: 'id',
      },
      {
        title: t('contacts.firstName'),
        dataIndex: ['agent', 'first_name'],
      },
      {
        title: t('contacts.lastName'),
        dataIndex: ['agent', 'last_name'],
      },

      {
        title: t('contacts.phoneNumber'),
        dataIndex: ['agent', 'phone_number'],
        render: (agentPhoneNumber) => <Put>{agentPhoneNumber}</Put>,
      },
      {
        title: t('filter.branch'),
        dataIndex: ['branch', 'name'],
        render: (branchName) => <Put>{branchName}</Put>,
      },
      {
        title: t('contacts.location'),

        render: ({ city }): React.ReactNode => (
          <div className="medium">
            <Put>{[city?.name, city?.region?.name].join(', ')}</Put>
          </div>
        ),
      },
      {
        title: t('filter.type'),
        render: ({ type }): React.ReactNode => <div className="medium">{type}</div>,
      },
      {
        title: t('created_at'),
        render: ({ created_at }) => (
          <div className="medium">
            <Put>{format(created_at, dateTimeFormat)}</Put>
          </div>
        ),
      },
      ...(useRolePermission([
        UserRoleCRM.marketing,
        UserRoleCRM.manager,
        UserRoleCRM.admin,
        UserRoleCRM.expert,
      ])
        ? [
            {
              align: 'right',
              render: ({ id, lead }): React.ReactNode => (
                <Actions showTitle={false}>
                  <div className="table-actions">
                    <div className="actions-title">{t('contacts.action')}</div>
                    {!lead && (
                      <Actions.Item onClick={() => push(`/visits/${id}/contacts`)}>
                        {t('visits.viewContacts')}
                      </Actions.Item>
                    )}
                    <Actions.Item onClick={() => push(`/visits/${id}`)}>
                      {t('visits.edit')}
                    </Actions.Item>
                    <Actions.Item onClick={() => setRemoveVisit(id)}>
                      {t('visits.delete')}
                    </Actions.Item>
                  </div>
                </Actions>
              ),
            } as Column<Visit>,
          ]
        : []),
    ],
    [t, visits, params],
  );

  return (
    <>
      <VisitsFilter count={visits?.count} />

      <Card>
        <Loader loading={isLoading} size="regular">
          <Table
            data={visits?.results}
            columns={columns}
            className="contacts-table no-border"
            emptyText={t('main.noData')}
          />
        </Loader>
        <Card.Footer>
          <Pagination size={visits?.total_pages || 0} />
        </Card.Footer>
      </Card>

      {!!removeVisit && (
        <Modal
          closeOnClickOutside={false}
          open
          header={<h3>{t('confirm.areYouSure')}</h3>}
          onClose={handleCancelDelete}
        >
          <Modal.Content>{t('confirm.areYouSureDescription')}</Modal.Content>
          <Modal.Footer>
            <Space justify="space-between">
              <Button onClick={handleCancelDelete}>{t('buttons.cancel')}</Button>
              <Button type="primary" onClick={handleDeleteVisit}>
                {t('buttons.ok')}
              </Button>
            </Space>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Visits;
