import React from 'react';
import { render } from 'react-dom';
import { App } from 'App/App';

import 'styles/index.scss';

render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    // returns installed service workers
    if (registrations.length) {
      for (const registration of registrations) {
        registration.unregister();
      }
    }
  });
}
